<template lang="pug">
	.reviews
		p.reviews__title {{ $t('landingNew.reviews.title') }}

		.reviews__list(ref="reviewsList")
			.reviews__item(v-for="(item, i) in !isMobile ? list : isReviews ? list : listShort" :key="`reviews-${i}`")
				img.reviews__avatar(:src="`/assets/img/landingNew/reviews/avatar-${i + 1}.png`")
				p.reviews__item-title(v-html="item.title")
				p.reviews__subtitle(v-html="item.subtitle")
				p.reviews__text(v-html="item.text")

		.reviews__button(v-if="isMobile" :class="{'rotate' : isReviews}" @click="toggleReviews")
			| {{ isReviews ? $t('landingNew.reviews.buttons[1]') : $t('landingNew.reviews.buttons[0]') }}
			svg(xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none")
				path(d="M1.00003 4.75439C1.00109 4.81129 1.015 4.86646 1.03998 4.91282C1.10876 5.01545 1.22617 5.02935 1.30765 4.9445L4.44785 1.53309C4.46391 1.51668 4.4835 1.50744 4.50379 1.50668C4.5254 1.50668 4.54643 1.51595 4.56371 1.53309L7.69992 4.9445C7.71888 4.96532 7.74053 4.98143 7.76384 4.99203C7.78765 4.99725 7.81195 4.99725 7.83575 4.99203C7.86078 4.98625 7.88502 4.97557 7.90767 4.96034C7.92884 4.94566 7.94779 4.92597 7.9636 4.90225C7.97816 4.87699 7.98901 4.84832 7.99556 4.81776C8.00148 4.78656 8.00148 4.75391 7.99556 4.72271C7.99912 4.6912 7.99912 4.65916 7.99556 4.62765C7.98297 4.59949 7.96674 4.57447 7.94762 4.55372L4.79943 1.14231C4.63301 0.952564 4.38655 0.952564 4.22013 1.14231L1.06794 4.56428C1.04621 4.58714 1.02849 4.61597 1.01601 4.64877C1.00502 4.68206 0.999568 4.71808 1.00003 4.75439Z" fill="#52A7F9" stroke="#52A7F9")
</template>

<script>
export default {
	name: 'Reviews',
	data: () => ({
		isReviews: false
	}),
	computed: {
		list() {
			return [
				{ title: this.$t('landingNew.reviews.list[0].title'), subtitle: this.$t('landingNew.reviews.list[0].subtitle'), text: this.$t('landingNew.reviews.list[0].text'), },
				{ title: this.$t('landingNew.reviews.list[1].title'), subtitle: this.$t('landingNew.reviews.list[1].subtitle'), text: this.$t('landingNew.reviews.list[1].text'), },
				{ title: this.$t('landingNew.reviews.list[2].title'), subtitle: this.$t('landingNew.reviews.list[2].subtitle'), text: this.$t('landingNew.reviews.list[2].text'), },
				{ title: this.$t('landingNew.reviews.list[3].title'), subtitle: this.$t('landingNew.reviews.list[3].subtitle'), text: this.$t('landingNew.reviews.list[3].text'), },
				{ title: this.$t('landingNew.reviews.list[4].title'), subtitle: this.$t('landingNew.reviews.list[4].subtitle'), text: this.$t('landingNew.reviews.list[4].text'), },
				{ title: this.$t('landingNew.reviews.list[5].title'), subtitle: this.$t('landingNew.reviews.list[5].subtitle'), text: this.$t('landingNew.reviews.list[5].text'), },
				{ title: this.$t('landingNew.reviews.list[6].title'), subtitle: this.$t('landingNew.reviews.list[6].subtitle'), text: this.$t('landingNew.reviews.list[6].text'), },
				{ title: this.$t('landingNew.reviews.list[7].title'), subtitle: this.$t('landingNew.reviews.list[7].subtitle'), text: this.$t('landingNew.reviews.list[7].text'), },
			];
		},
		listShort() {
			return [
				{ title: this.$t('landingNew.reviews.list[0].title'), subtitle: this.$t('landingNew.reviews.list[0].subtitle'), text: this.$t('landingNew.reviews.list[0].text'), }
			];
		},
	},
	methods: {
		toggleReviews() {
			if (this.isReviews == true)
				setTimeout(() => {
					this.$refs.reviewsList.scrollIntoView({block: "center", inline: "center"});
				}, 33);

			this.isReviews = !this.isReviews;
		}
	}
}
</script>

<style lang="scss">
.reviews {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 112px 95px;
	background-color: var(--lc-blue);
	border-radius: 80px;
	overflow: hidden;

	@media(max-width: 1900px) {
		padding: 70px 50px;
		background-color: var(--lc-blue);
		border-radius: 40px;
	}

	@media(max-width: 1320px) {
		padding: 70px 40px;
		background-color: var(--lc-blue);
	}

	@media(max-width: 991px) {
		padding: 50px 20px;
	}

	@media(max-width: 767px) {
		align-items: flex-start;
		padding: 34px 15px;
		border-radius: 20px;
	}

	&__title {
		font-size: 83px;
		line-height: 90%;
		/* 74.7px */
		text-align: center;
		color: var(--lc-white);
		margin-bottom: 130px;

		@media(max-width: 1900px) {
			font-size: 55px;
			margin-bottom: 70px;
		}

		@media(max-width: 1320px) {
			font-size: 40px;
			margin-bottom: 50px;
		}

		@media(max-width: 991px) {
			font-size: 36px;
			margin-bottom: 40px;
		}

		@media(max-width: 767px) {
			font-size: 28px;
			line-height: 100%;
			/* 28px */
			text-align: start;
			margin-bottom: 10px;
		}
	}

	&__list {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	&__item {
		display: flex;
		flex-direction: column;
		padding: 40px;
		width: calc(33% - 40px);
		height: 543px;
		border-radius: 45px;
		background-color: rgba(255, 255, 255, 0.17);
		margin-bottom: 60px;

		&:nth-child(4),
		&:nth-child(5),
		&:nth-child(6) {
			height: 599px;
		}

		&:nth-child(7),
		&:nth-child(8) {
			width: calc(50% - 30px);
			height: 631px;
		}

		@media(max-width: 1900px) {
			padding: 20px;
			width: calc(33% - 20px);
			height: 484px;
			border-radius: 30px;
			margin-bottom: 30px;

			&:nth-child(4),
			&:nth-child(5),
			&:nth-child(6) {
				height: 456px;
			}

			&:nth-child(7),
			&:nth-child(8) {
				width: calc(50% - 15px);
				height: 501px;
			}
		}

		@media(max-width: 1320px) {
			width: calc(33% - 13.33px);
			height: 309px;
			border-radius: 25px;
			margin-bottom: 20px;

			&:nth-child(4),
			&:nth-child(5),
			&:nth-child(6) {
				height: 313px;
			}

			&:nth-child(7),
			&:nth-child(8) {
				width: calc(50% - 10px);
				height: 341px;
			}
		}

		@media(max-width: 991px) {
			width: calc(33% - 8px);
			height: 309px;
			padding: 12px;
			margin-bottom: 12px;

			&:nth-child(4),
			&:nth-child(5),
			&:nth-child(6) {
				height: 293px;
			}

			&:nth-child(7),
			&:nth-child(8) {
				width: calc(50% - 8px);
				height: 321px;
			}
		}

		@media(max-width: 767px) {
			padding: 20px;
			width: 100%;
			height: auto;
			min-height: 276px;
			margin: 10px 0 0;

			&:nth-child(4),
			&:nth-child(5),
			&:nth-child(6) {
				width: 100%;
				height: auto;
			}

			&:nth-child(7),
			&:nth-child(8) {
				width: 100%;
				height: auto;
			}
		}
	}

	&__avatar {
		display: block;
		width: 129px;
		height: 129px;
		border-radius: 50%;
		margin-bottom: 18px;

		@media(max-width: 1900px) {
			width: 100px;
			height: 100px;
			margin-bottom: 15px;
		}

		@media(max-width: 1320px) {
			width: 67px;
			height: 67px;
			margin-bottom: 10px;
		}

		@media(max-width: 991px) {
			width: 57px;
			height: 57px;
		}

		@media(max-width: 767px) {
			width: 70px;
			height: 70px;
		}
	}

	&__item-title {
		font-size: 36px;
		color: var(--lc-white);
		margin: 0;

		@media(max-width: 1900px) {
			font-size: 34px;
		}

		@media(max-width: 1320px) {
			font-size: 18px;
		}

		@media(max-width: 991px) {
			font-size: 16px;
		}

		@media(max-width: 767px) {
			font-size: 18px;
		}
	}

	&__subtitle {
		display: flex;
		align-items: center;
		min-height: 69px;
		font-size: 20px;
		font-weight: 300;
		color: rgba(255, 255, 255, 0.60);
		margin: 18px 0;

		@media(max-width: 1900px) {
			min-height: 21px;
			font-size: 18px;
		}

		@media(max-width: 1320px) {
			font-size: 12px;
			margin: 10px 0;
		}

		@media(max-width: 991px) {
			font-size: 11px;
		}

		@media(max-width: 767px) {
			font-size: 12px;
		}
	}

	&__text {
		display: flex;
		font-size: 24px;
		font-weight: 400;
		color: var(--lc-white);
		margin: 0;

		@media(max-width: 1900px) {
			font-size: 20px;
		}

		@media(max-width: 1320px) {
			font-size: 14px;
		}

		@media(max-width: 991px) {
			font-size: 13px;
		}

		@media(max-width: 767px) {
			font-size: 14px;
		}
	}

	&__button {
		padding: 10px 15px;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px;
		color: var(--lc-blue);
		border-radius: 20px;
		background-color: var(--lc-white);
		margin-top: 20px;

		svg {
			transition: all 0.4s ease;
			transform: rotate(180deg);
			margin-left: 6px;
		}

		&.rotate {
			svg {
				transform: rotate(0);
			}
		}
	}
}
</style>
