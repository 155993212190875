<template lang="pug">
	.create
		picture
			source(srcset="/assets/img/landingNew/create/create-bg-mobile.png" media="(max-width: 767px)")
			source(srcset="/assets/img/landingNew/create/create-bg-tablet.png" media="(max-width: 1320px)")
			source(srcset="/assets/img/landingNew/create/create-bg-desktop.png" media="(max-width: 1900px)")
			img.create__bg-img(src="/assets/img/landingNew/create/create-bg.png" alt="preview boy")
		.create__decor.create__decor--first
			img(src="/assets/img/landingNew/create/smiley-1.svg")
			span 108
		.create__decor.create__decor--second
			img(src="/assets/img/landingNew/create/smiley-2.svg")
			span 169
		.create__decor.create__decor--third
			img(src="/assets/img/landingNew/create/smiley-3.svg")
			span 59
		.create__decor.create__decor--fourth
			img(src="/assets/img/landingNew/create/smiley-4.svg")
			span 89
		.create__content
			span.create__title {{ $t('landingNew.create.title[0]') }}
			span.create__title.create__title--blue {{ $t('landingNew.create.title[1]') }}
			span.create__title(v-html="$t('landingNew.create.title[2]')")
			p.create__text(v-html="$t('landingNew.create.text')")
			my-link.create__button(:text="$t('landingNew.create.button')" :link="'javascript:void(0)'" @click.native="$emit('click', $event)")
</template>

<script>
import MyLink from '@/components/LandingNew/UI/MyLink';

export default {
	name: 'Create',
	components: {
		MyLink,
	}
}
</script>

<style lang="scss" scoped>
.create {
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 111px 125px 133px;
	background-color: var(--lc-blue);
	border-radius: 80px;

	@media(max-width: 1900px) {
		padding: 77px 96px 76px;
		border-radius: 40px;
	}

	@media(max-width: 1320px) {
		padding: 60px 66px 60px;
	}

	@media(max-width: 991px) {
		padding: 40px 26px 50px;
	}

	@media(max-width: 767px) {
		padding: 0 15px 30px;
		border-radius: 20px;
	}

	&__decor {
		position: absolute;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: max-content;
		background-color: rgba(255, 255, 255, 0.7);
		overflow: hidden;

		span {
			font-weight: 500;
			color: var(--lc-blue);
		}

		&--first {
			top: 137px;
			left: 34%;
			width: 147.745px;
			height: 73.282px;
			padding: 4px 26px;
			transform: rotate(6.871deg);
			border-radius: 35.459px;
			box-shadow: 0 0 94.55695px 0 var(--lc-white) inset;

			span {
				font-size: 33.095px;
			}

			img {
				width: 38.277px;
				height: 33.549px;
			}

			@media(max-width: 1900px) {
				top: 124px;
				left: 486px;
				width: 100px;
				height: auto;
				padding: 10px 14px;

				span {
					font-size: 24px;
				}

				img {
					width: 29px;
					height: 29px;
				}
			}

			@media(max-width: 1320px) {
				top: 117px;
				left: 376px;
				width: 78.293px;
				height: 37.825px;
				padding: 4px 13px;

				span {
					font-size: 16px;
				}

				img {
					width: 23.628px;
					height: 21.059px;
				}
			}

			@media(max-width: 991px) {
				top: 97px;
				left: 296px;
			}

			@media(max-width: 767px) {
				top: 52px;
				left: auto;
				right: 22px;
				width: 49.038px;
				height: 24.323px;
				padding: 4px 9px;
				transform: rotate(6.871deg);

				span {
					font-size: 11px;
				}

				img {
					width: 11px;
					height: auto;
					transform: rotate(-6.871deg);
				}
			}
		}

		&--second {
			top: 246px;
			left: 27%;
			width: 154.88px;
			height: 76.821px;
			padding: 4px 30px 4px 24px;
			transform: rotate(12.114deg);
			border-radius: 37.171px;
			box-shadow: 0 0 99.12347px 0 var(--lc-white) inset;

			span {
				font-size: 34.693px;
			}

			img {
				width: 52.186px;
				height: 52.354px
			}

			@media(max-width: 1900px) {
				top: 200px;
				left: 380px;
				width: 104px;
				height: auto;
				padding: 8px 14px;

				span {
					font-size: 23px;
				}

				img {
					width: 36px;
					height: 36px;
				}
			}

			@media(max-width: 1320px) {
				top: 181px;
				left: 297px;
				width: 77.17px;
				height: 40.748px;
				padding: 4px 11px;

				span {
					font-size: 17px;
				}

				img {
					width: 29.924px;
					height: 29.007px;
				}
			}

			@media(max-width: 991px) {
				top: 161px;
				left: 267px;
			}

			@media(max-width: 767px) {
				top: 84px;
				left: auto;
				right: 66px;
				width: 51.406px;
				height: 25.498px;
				padding: 4px 9px 4px 7px;
				transform: rotate(12.114deg);

				span {
					font-size: 11px;
				}

				img {
					width: 17px;
					height: auto;
					transform: rotate(-12.49deg);
				}
			}
		}

		&--third {
			top: 317px;
			left: 37%;
			width: 114px;
			height: 62px;
			padding: 4px 22px;
			transform: rotate(-5.226deg);
			border-radius: 25px;
			box-shadow: 0px 0px 80px 0px var(--lc-white) inset;

			span {
				font-size: 28px;
			}

			img {
				width: 30.647px;
				height: 30.926px;
			}

			@media(max-width: 1900px) {
				top: 254px;
				left: 530px;
				width: 74px;
				height: auto;
				padding: 8px 11px;

				span {
					font-size: 20px;
				}

				img {
					width: 22px;
					height: 22px;
				}
			}

			@media(max-width: 1320px) {
				top: 224px;
				left: 406px;
				width: 58.954px;
				height: 32.694px;
				padding: 4px 11px;

				span {
					font-size: 14px;
				}

				img {
					width: 17.486px;
					height: 17.622px;
				}
			}

			@media(max-width: 991px) {
				top: 214px;
				left: 366px;
			}

			@media(max-width: 767px) {
				top: 111px;
				left: auto;
				right: 14px;
				width: 37.838px;
				height: 20.578px;
				padding: 4px 6px;
				transform: rotate(-5.226deg);

				span {
					font-size: 10px;
				}

				img {
					width: 10px;
					height: auto;
					transform: rotate(5.226deg)
				}
			}
		}

		&--fourth {
			top: 428px;
			left: 30%;
			width: 158.437px;
			height: 86.168px;
			padding: 4px 31px 4px 27px;
			transform: rotate(-5.652deg);
			border-radius: 34.745px;
			box-shadow: 0px 0px 111.18394px 0px var(--lc-white) inset;

			span {
				font-size: 38.914px;
			}

			img {
				width: 46.739px;
				height: 46.103px;
			}

			@media(max-width: 1900px) {
				top: 345px;
				left: 436px;
				width: 110px;
				height: auto;
				padding: 9px 16px;

				span {
					font-size: 30px;
				}

				img {
					width: 38px;
					height: 38px;
				}
			}

			@media(max-width: 1320px) {
				top: 285px;
				left: 336px;
				width: 80.103px;
				height: 42.828px;
				padding: 4px 12px;

				span {
					font-size: 18px;
				}

				img {
					width: 30.486px;
					height: 28.622px;
				}
			}

			@media(max-width: 991px) {
				top: 265px;
				left: 296px;
			}

			@media(max-width: 767px) {
				top: 148px;
				left: auto;
				right: 42px;
				width: 52.587px;
				height: 28.6px;
				padding: 9px 8px;
				transform: rotate(-5.652deg);

				span {
					font-size: 14px;
				}

				img {
					width: 17px;
					height: auto;
					transform: rotate(5.652deg)
				}
			}
		}
	}

	&__bg-img {
		position: absolute;
		bottom: 0;
		left: 0;
		height: 1016px;

		@media(max-width: 1900px) {
			height: 812px;
		}

		@media(max-width: 1320px) {
			height: 540px;
		}

		@media(max-width: 991px) {
			height: 464px;
		}

		@media(max-width: 767px) {
			position: static;
			width: auto;
			bottom: auto;
			top: 0;
			width: 100%;
			height: auto;
			margin-bottom: 12px;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		width: 830px;
		margin-left: auto;

		@media(max-width: 1900px) {
			width: 565px;
		}

		@media(max-width: 1320px) {
			width: 404px;
		}

		@media(max-width: 991px) {
			width: 304px;
		}

		@media(max-width: 767px) {
			width: 100%;
		}
	}

	&__title {
		font-size: 83px;
		font-weight: 400;
		line-height: 100px;
		/* 120.482% */
		text-align: center;
		color: var(--lc-white);

		@media(max-width: 1900px) {
			font-size: 55px;
			line-height: 94px;
			/* 181.818% */
		}

		@media(max-width: 1320px) {
			font-size: 40px;
			line-height: 50px;
			/* 125% */
		}

		@media(max-width: 991px) {
			font-size: 30px;
			line-height: 40px;
		}

		@media(max-width: 767px) {
			font-size: 28px;
			line-height: 30.5px;
			/* 108.929% */
			text-align: start;
		}

		&--blue {
			display: flex;
			width: max-content;
			padding: 0 14px 7px;
			color: var(--lc-blue);
			border-radius: 30px;
			background-color: var(--lc-white);
			margin: 7px auto 0;

			@media(max-width: 1900px) {
				padding: 1px 34px 8px;
				line-height: 84px;
				/* 181.818% */
				border-radius: 30px;
			}

			@media(max-width: 1320px) {
				padding: 1px 10px 8px;
				line-height: 90%;
				/* 36px */
				border-radius: 15px;
			}

			@media(max-width: 991px) {
				line-height: 80%;
				/* 36px */
			}

			@media(max-width: 767px) {
				padding: 1px 5px 7px;
				line-height: 90%;
				/* 25.2px */
				border-radius: 10px;
				margin: 8px 0 0;
			}
		}

		&:last-of-type {
			line-height: 81.5px;
			/* 98.193% */

			@media(max-width: 1900px) {
				line-height: 60px;
			}

			@media(max-width: 1320px) {
				line-height: 40px;
				/* 100% */
			}

			@media(max-width: 767px) {
				line-height: 30.5px;
				/* 108.929% */
			}
		}
	}

	&__text {
		max-width: 521px;
		font-size: 24px;
		font-weight: 300;
		text-align: center;
		color: var(--lc-white);
		margin: 56px auto;

		@media(max-width: 1900px) {
			max-width: 426px;
			font-size: 20px;
			margin: 45px auto;
		}

		@media(max-width: 1320px) {
			max-width: 303px;
			font-size: 14px;
			margin: 40px auto;
		}

		@media(max-width: 991px) {
			font-size: 13px;
			margin: 30px auto;
		}

		@media(max-width: 767px) {
			font-size: 14px;
			text-align: start;
			margin: 15px 0 30px;
		}
	}

	&__button {
		position: relative;
		z-index: 1;
		width: 482px;
		margin: 0 auto;

		@media(max-width: 1900px) {
			width: 416px;
		}

		@media(max-width: 1320px) {
			width: 248px;
		}

		@media(max-width: 767px) {
			width: 100%;
		}
	}
}</style>
