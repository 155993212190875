<template lang="pug">
	.payments
		img.payments__bg-img(:src="`/assets/img/landingNew/payments/${isLocaleRu ? 'Ru' : 'Eng'}.png`"  alt="phone")
		img.payments__money(src="/assets/img/landingNew/payments/money.png" alt="money")
		span.payments__title {{ $t('landingNew.payments.title[0]') }}
		span.payments__title {{ $t('landingNew.payments.title[1]') }}
		span.payments__title.payments__title--blue {{ $t('landingNew.payments.title[2]') }}
		p.payments__description(v-html="$t('landingNew.payments.description')")
		.payments__message
			span {{ $t('landingNew.payments.message[0]') }}
			span {{ $t('landingNew.payments.message[1]') }}
			h4 {{ $t('landingNew.payments.message[2]') }}
			p {{ $t('landingNew.payments.message[3]') }}
		my-link.payments__button(:text="$t('landingNew.payments.button')" :link="'javascript:void(0)'" @click.native="$emit('click', $event)")
</template>

<script>
import MyLink from '@/components/LandingNew/UI/MyLink';

export default {
	name: 'Payments',
	components: {
		MyLink,
	}
}
</script>

<style lang="scss" scoped>
.payments {
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 121px 128px 137px;
	background-color: var(--lc-blue);
	border-radius: 80px;
	overflow: hidden;

	@media(max-width: 1900px) {
		padding: 70px 55px 91px;
		border-radius: 40px;
	}

	@media(max-width: 1320px) {
		padding: 70px 56px;
	}

	@media(max-width: 991px) {
		padding: 60px 36px;
	}

	@media(max-width: 767px) {
		padding: 30px 15px 277px;
		border-radius: 20px;
	}

	&__bg-img {
		position: absolute;
		z-index: 1;
		bottom: -158px;
		right: 212px;
		width: 461px;

		@media(max-width: 1900px) {
			right: 140px;
			width: 430px;
		}

		@media(max-width: 1320px) {
			right: 90px;
			width: 320px;
		}

		@media(max-width: 991px) {
			right: 70px;
			bottom: -60px;
			width: 244px;
		}

		@media(max-width: 767px) {
			z-index: 1;
			left: 50%;
			bottom: -180px;
			width: 168;
			transform: translateX(-50%);
		}
	}

	&__money {
		position: absolute;
		top: -20px;
		right: 0;
		display: block;
		width: 800px;

		@media(max-width: 1900px) {
			width: 600px;
		}

		@media(max-width: 1320px) {
			width: 400px;
		}

		@media(max-width: 991px) {
			width: 300px;
		}

		@media(max-width: 767px) {
			position: absolute;
			top: auto;
			bottom: 0;
			right: 0;
			display: block;
		}
	}

	&__title {
		width: max-content;
		font-size: 83px;
		font-weight: 400;
		line-height: 90%;
		/* 90px */
		color: var(--lc-white);
		margin-bottom: 11px;

		@media(max-width: 1900px) {
			font-size: 55px;
			line-height: 110%;
			margin-bottom: 0;
		}

		@media(max-width: 1320px) {
			font-size: 40px;
			line-height: 90%;
			/* 36px */
			margin-bottom: 5px;
		}

		@media(max-width: 991px) {
			font-size: 34px;
		}

		@media(max-width: 767px) {
			font-size: 28px;
			margin-bottom: 4px;
		}

		&--blue {
			padding: 16px 27px 20px;
			color: var(--lc-blue);
			border-radius: 30px;
			background-color: var(--lc-white);
			margin: 8px 0 57px;

			@media(max-width: 1900px) {
				padding: 9.5px 30px 11px;
				font-size: 55px;
				line-height: 110%;
				border-radius: 30px;
				margin: 12px 0 45px
			}

			@media(max-width: 1320px) {
				padding: 1px 10px 8px;
				font-size: 40px;
				line-height: 90%;
				/* 36px */
				border-radius: 15px;
				margin: 5px 0 30px
			}

			@media(max-width: 991px) {
				font-size: 34px;
			}

			@media(max-width: 767px) {
				padding: 2px 5px 6px;
				font-size: 28px;
				line-height: 90%;
				/* 25.2px */
				border-radius: 10px;
				margin: 0 0 15px;
			}
		}
	}

	&__description {
		max-width: 714px;
		font-size: 28px;
		font-weight: 300;
		color: var(--lc-white);
		margin: 0 0 57px;

		@media(max-width: 1900px) {
			max-width: 426px;
			font-size: 20px;
			margin: 0 0 45px;
		}

		@media(max-width: 1320px) {
			max-width: 391px;
			font-size: 16px;
			margin: 0 0 40px;
		}

		@media(max-width: 912px) {
			max-width: 321px;
			font-size: 15px;
			margin: 0 0 35px;
		}

		@media(max-width: 767px) {
			font-size: 14px;
			margin: 0 0 30px;
		}
	}

	&__button {
		position: relative;
		z-index: 1;
		max-width: 482px;

		@media(max-width: 1900px) {
			max-width: 416px;
		}

		@media(max-width: 1320px) {
			max-width: 248px;
		}

		@media(max-width: 767px) {
			max-width: 100%;
		}
	}

	&__message {
		position: absolute;
		z-index: 1;
		bottom: 112px;
		right: 580px;
		padding: 30px;
		width: 263px;
		height: 151px;
		transform: rotate(-6.01deg);
		box-shadow: 0 0 80px 0 var(--lc-white) inset;
		background-color: rgba(255, 255, 255, 0.9);
		border-radius: 30px;
		overflow: hidden;

		@media(max-width: 1900px) {
			position: absolute;
			bottom: 94px;
			right: 491px;
			padding: 12px 18px;
			width: 216px;
			height: min-content;
			border-radius: 25px;
		}

		@media(max-width: 1320px) {
			position: absolute;
			bottom: 64px;
			right: 320px;
			padding: 10px 15px;
			width: 177px;
			border-radius: 20px;
		}

		@media(max-width: 991px) {
			right: 256px;
			padding: 5px 10px;
			width: 128px;
			border-radius: 16px;
		}

		@media(max-width: 767px) {
			z-index: 1;
			bottom: 32px;
			right: auto;
			left: 26%;
			padding: 4px 10px 7px;
			width: 126px;
			height: max-content;
			transform: rotate(-6.01deg);
			border-radius: 14.232px;
		}

		@media(max-width: 560px) {
			left: 21%;
		}

		@media(max-width: 560px) {
			left: 16%;
		}

		@media(max-width: 420px) {
			left: 11%;
		}

		@media(max-width: 320px) {
			left: 6%;
		}

		span {
			display: inline-block;
			font-size: 14px;
			font-weight: 300;
			line-height: 130%;
			/* 18.2px */
			margin-bottom: 4px;

			@media(max-width: 1900px) {
				font-size: 13px;
			}

			@media(max-width: 1320px) {
				font-size: 12px;
				margin-bottom: 2px;
			}

			@media(max-width: 991px) {
				font-size: 8px;
			}

			@media(max-width: 767px) {
				font-size: 8.539px;
				margin-bottom: 0;
			}

			&:first-child {
				position: relative;
				padding-right: 17px;

				&::before {
					content: '';
					position: absolute;
					top: 50%;
					right: 7px;
					min-width: 2px;
					min-height: 2px;
					border-radius: 50%;
					background-color: var(--lc-dark);
					transform: translateY(-50%);
				}
			}
		}

		h4 {
			font-size: 18px;
			font-weight: 400;
			line-height: 130%;
			/* 23.4px */
			margin-bottom: 4px;

			@media(max-width: 1900px) {
				font-size: 16px;
			}

			@media(max-width: 1320px) {
				font-size: 14px;
				margin-bottom: 2px;
			}

			@media(max-width: 991px) {
				font-size: 10px;
			}

			@media(max-width: 767px) {
				font-size: 9.963px;
				margin-bottom: 0;
			}
		}

		p {
			font-size: 18px;
			font-weight: 300;
			line-height: 130%;
			/* 23.4px */
			margin-bottom: 0;

			@media(max-width: 1900px) {
				font-size: 16px;
			}

			@media(max-width: 1320px) {
				font-size: 14px;
			}

			@media(max-width: 991px) {
				font-size: 10px;
			}

			@media(max-width: 767px) {
				font-size: 9.963px;
			}
		}
	}
}
</style>
